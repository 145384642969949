<template>
  <component
    :is="componentName"
    :href="contentPath"
    :class="classObject"
    :title="content.title"
    :time="content.time"
    :mile="content.amount"
    :icon="content.icon"
    :barista="content.barista"
    :type="content.type"
    :src="content.src"
    :description="content.description" />
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  components: {
    MileHistoryCard: () =>
      import('@/components/mypage/mileHistory/MileHistoryCard.vue')
  },

  props: {
    logCardType: {
      type: String,
      default: 'mileHistory'
    },

    content: {
      type: Object,
      default: null
    },

    // サムネイルを角丸にしない（芸能人が入る可能性のある箇所）
    squaredThumbnail: {
      type: Boolean,
      default: false
    }
  },

  setup: (props) => {
    const FAVORITE_CONTENT_TYPES = [
      {
        id: 1,
        name: 'イベント',
        path: 'coffee-creation-app://page/detail/?url=/content/join/detail/'
      },
      {
        id: 2,
        name: 'プロコーヒー',
        path: 'coffee-creation-app://page/detail/?url=/coffee/detail/'
      },
      {
        id: 3,
        name: '動画',
        path: 'coffee-creation-app://page/standalone/?url=/video/detail/'
      }
    ];

    const componentName = computed(() => {
      let value = '';
      switch (props.logCardType) {
        case 'favorite':
          value = 'CoffeeCardTiled';
          break;

        default:
          value = 'MileHistoryCard';
          break;
      }
      return value;
    });

    const favoriteContentTypeName = computed(() => props.content.type);

    const favoriteContentType = computed(
      () =>
        FAVORITE_CONTENT_TYPES.filter(
          (type) => type.name === favoriteContentTypeName.value
        )[0]
    );

    const contentId = computed(() => {
      if (props.logCardType !== 'favorite') return false;
      let value = '';
      switch (favoriteContentTypeName.value) {
        case 'イベント':
          value = props.content?.event_id;
          break;

        case 'プロコーヒー':
          value = props.content?.product_id;
          break;

        case '動画':
          value = props.content?.movie_id;
          break;

        default:
          value = '';
          break;
      }
      return value;
    });

    const contentPath = computed(() =>
      props.logCardType === 'favorite'
        ? favoriteContentType.value.path + contentId.value
        : false
    );

    const classObject = computed(() => {
      return [
        'log-card',
        props.logCardType === 'favorite' ? 'log-card--favorite' : ''
      ];
    });

    return {
      FAVORITE_CONTENT_TYPES,
      componentName,
      favoriteContentType,
      contentPath,
      classObject
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';
.log-card {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.6rem 0;
  width: 100%;
  overflow: hidden;
  font-size: 1.3rem;
  line-height: 1.5;
  &--favorite::v-deep {
    .c-coffeeCardTiled__img {
      img {
        border-radius: 0;
        object-fit: contain;
      }
    }
  }

  &__icon {
    flex: 0 0 56px;
    height: 56px;
    display: grid;
    place-items: center;
    background: variables.$grey04;
    border-radius: 999px;
  }

  &__content {
    flex: 1 1 100%;
    padding: 0 1rem 0 1.6rem;
  }

  &__mile {
    flex: 1 0 auto;
    font-size: 2.4rem;
    font-weight: bold;
    color: variables.$secondary01;
  }

  & + .log-card {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: '';
      background-color: variables.$grey04;
      width: 100%;
      height: 1px;
    }
  }
}
</style>
