var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.componentName, {
    tag: "component",
    class: _vm.classObject,
    attrs: {
      href: _vm.contentPath,
      title: _vm.content.title,
      time: _vm.content.time,
      mile: _vm.content.amount,
      icon: _vm.content.icon,
      barista: _vm.content.barista,
      type: _vm.content.type,
      src: _vm.content.src,
      description: _vm.content.description,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }